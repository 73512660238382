import { CountryInterface } from ".";
import { Category } from "./products";
import { Plan, SubscriptionInterface } from "./subscriptions";

export enum CURRENCIES {
  GHC = "GHS",
  NGN = "NGN",
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  ZAR = "ZAR",
}

export interface StoreCurrencySettings {
  default: CURRENCIES;
  products: CURRENCIES;
  storefront: CURRENCIES[];
  storefront_default: CURRENCIES;
  rates: { [key: string]: number };
}

export interface StoreInterface {
  disabled?: boolean;
  delivery_locations?: string;
  total_visits: number;
  socials?: {
    twitter?: string;
    instagram?: string;
    facebook?: string;
  };
  hero_image?: string;
  logo?: string;
  country: CountryInterface;
  state?: string;
  address?: string;
  slug: string;
  name: string;
  phone: string;
  description: string;
  owner?: string;
  categories?: Category[];
  created_at?: string;
  updated_at?: string;
  item_count?: number;
  items_count?: number;
  plan?: Plan;
  configuration: {
    view_modes: {
      grid: boolean;
      card: boolean;
      horizontal: boolean;
      default: string;
    };
    hours?: { [key: string]: string } | null;
    fb_pixel?: string;
    ga_id?: string;
    custom_message?: string;
    enquiry_message?: string;
    direct_checkout_enabled?: boolean;
    sort_by_latest_products: boolean;
    show_unavailable_products: boolean;
    customer_pickup_enabled?: boolean;
    whatsapp_checkout_enabled?: boolean;
    facebook_pixel_enabled?: boolean;
    require_delivery_info?: boolean;
    color?: string;
    payment_validates_order?: boolean;
    require_emails?: boolean;
    payment_timeout?: number;
  };
  id: string;
  delivery_areas: DeliveryArea[] | string[];
  checkout_channels?: CheckoutChannels;
  owners?: {
    user: string;
    role: string;
    email: string;
    name: string;
  }[];
  subscription?: SubscriptionInterface | any;
  onboarding_steps: {
    products_added?: boolean;
    link_added: boolean;
  };
  invites?: string[];
  disabled_slugs?: string[];
  slugs?: string[];
  payments_enabled?: boolean;
  payment_options?: { enabled: boolean; type: string }[];
  currencies?: StoreCurrencySettings;
  maintenance_mode?: boolean;
  deliveries_enabled?: boolean;
  extra_info?: {
    delivery_timeline?: string;
    production_timeline?: string;
    refund_policy?: string;
    images_label?: string;
    images?: string[];
  };
  has_paid_subscription?: boolean;
}

export interface DeliveryArea {
  id: string;
  name: string;
  fee: number;
  store?: string;
}

export interface CheckoutChannels {
  whatsapp: WhatsappCheckoutOption[];
  instagram?: InstagramCheckoutOption;
}

export interface WhatsappCheckoutOption {
  id: string;
  label: string;
  phone?: string;
  type: "WHATSAPP";
  primary?: boolean;
}

export interface InstagramCheckoutOption {
  id: string;
  username: string;
  type: "INSTAGRAM";
  enabled?: boolean;
}

export interface WABotSession {
  name: string;
  phone: string;
  id: string;
  cart_id?: string;
}

export interface Branches {
  id: string;
  name: string;
  branches: {
    store_id: string;
    label: string;
    is_head_branch: boolean;
    store: {
      configuration?: StoreInterface["configuration"];
      logo: string;
      slug: string;
      name: string;
    };
    open_or_closed: {
      open: boolean;
      visited_before_opening?: boolean;
      visited_after_closing?: boolean;
      open_time?: string;
      close_time?: string;
      open_tomorrow_time?: string;
      is_on_maintenance?: boolean;
    };
  }[];
  primary_store: StoreInterface;
  bot_phone?: string;
}
